.block {
  margin-top: 40px;
  width: 100%;
  height: max-content;
  background: rgba(255, 255, 255, 1);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.content-block {
  width: calc(100% - 80px);
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.content-block img {
  width: 530px;
  height: 530px;
}
.content {
  max-width: 550px;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 25px;
  font-weight: 600;
  font-family: var(--font-open);
  font-style: normal;
  line-height: 34.05px;
  color: rgba(40, 40, 40, 1);
}
.item-block {
  margin-top: 30px;
  width: 100%;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(30px);
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
}
.show-element{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
}
.item:not(:first-of-type) {
  margin-top: 15px;
}
.item__title {
  font-size: 18px;
  font-weight: 400;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 23.4px;
  color: rgba(40, 40, 40, 1);
}
.item__price {
  font-size: 18px;
  font-weight: 600;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 23.4px;
  color: rgba(52, 165, 115, 1);
}
.description {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 23.4px;
  color: rgba(40, 40, 40, 1);
}
.description span {
  color: rgba(52, 165, 115, 1);
}
.content button {
  margin-top: 20px;
  width: 100%;
  height: 55px;
  border: none;
  outline: none;
  border-radius: 15px;
  background: rgba(94, 109, 226, 1);
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 25.2px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

@media (max-width: 1180px) {
  .content-block img {
    width: 430px;
    height: max-content;
  }
}

@media (max-width: 1024px) {
  .content-block {
    width: calc(100% - 80px);
    flex-direction: column;
  }
  .content-block img {
    width: 100%;
    height: max-content;
  }
  .content {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .content-block {
    width: calc(100% - 40px);
    margin: 20px 0;
  }
  .title {
    width: 90%;
    font-size: 18px;
    line-height: 27.24px;
  }
  .item-block {
    margin-top: 20px;
  }
  .item__title {
    font-size: 16px;
    line-height: 20.8px;
  }
  .item__price {
    font-size: 16px;
    line-height: 20.8px;
  }
  .description {
    max-width: 87%;
    font-size: 14px;
    line-height: 19.6px;
  }
}
