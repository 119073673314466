.header {
  width: 100vw;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(40, 40, 40, 1);
  position: fixed;
  top: 0;
  z-index: 1;
}
.content {
  width: calc(100% - 240px);
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.logo {
  width: 191px;
  height: 29px;
}
.link-block {
  width: 490px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.link-block a {
  text-decoration: none;
}
.link-block a:hover{
  color: #34A573;
}

.link-block__item {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 19.2px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.contact-block {
  width: 299px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.lang-block {
  width: 130px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.lang-block__item {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  width: max-content;
  height: max-content;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.contact-block__item {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}
.contact-block a{
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
} 
.menu-btn {
  display: none;
  width: max-content;
  height: max-content;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

@media (max-width:1340px){
  .content{
    width: calc(100% - 80px);
  }
}

@media (max-width: 1024px) {
  .content {
    width: calc(100% - 80px);
  }
  .link-block {
    display: none;
  }
  .contact-block {
    display: none;
  }
  .menu-btn {
    display: block;
  }
}

@media (max-width: 480px) {
  .header {
    height: 60px;
  }
  .content {
    width: calc(100% - 40px);
  }
  .logo {
    width: 114px;
    height: 17px;
  }
}
