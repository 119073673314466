.block {
  margin-top: 40px;
  width: 100%;
  border-radius: 15px;
  background: rgba(255, 255, 255, 1);
  
}
.content-block-wrapper{
  width: calc(100% - 80px);
  margin:0 auto;
  padding: 20px 0px 20px 0px;
  
  
}
.content-block {
  max-width: 600px;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
}
.item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
 
  
}
.item:not(:first-of-type) {
  margin-top: 40px;
}
.item-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
.button-wrapper{
  max-width: 478px;
  margin: 0 auto;
 
}
.button-wrapper button {
  margin-top: 20px;
    width: 100%;
    height: 55px;
    border: none;
    outline: none;
    border-radius: 15px;
    background: rgba(94, 109, 226, 1);
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 25.2px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.title-block {
  width: 200px;
  display: flex;
  flex-direction: column;
}
.item__title {
  font-size: 18px;
  font-weight: 400;
  font-family: var(--font-open);
  font-style: normal;
  line-height: 23.4px;
  color: rgba(40, 40, 40, 1);
}
.item__description {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 20.8px;
  color: rgba(40, 40, 40, 1);

}

.item button {
  width: 300px;
  height: 55px;
  border-radius: 15px;
  border: none;
  outline: none;
  background: rgba(94, 109, 226, 1);
  font-size: 18px;
  font-weight: 600;
  font-family: var(--font-open);
  font-style: normal;
  line-height: 25.2px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.item__price {
  margin-top: 5px;
  width: 270px;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 23.4px;
  color: rgba(52, 165, 115, 1);
}

@media (max-width:1024px){
  .item-block{
    flex-direction: column;
  }
  .item__price {
    margin-top: 10px;
  }
  .item button {
    width: 350px;
  }
}

@media (max-width:480px){
  .content-block{
    width: calc(100% - 40px);
    margin: 20px 0;
  }
  .item{
    flex-direction: column;
  }
  .item:not(:first-of-type) {
    margin-top: 30px;
  }
  .title-block{
    width: 100%;
  }
  .item-block{
    width: 100%;
  }
  .item__title{
    font-size: 18px;
  }
  .item__price {
    margin-top: 0;
  }
  .item__description{
    width: 100%;
    text-align: start;
    margin-top: 0;
  }
  .item button {
    width: 100%;
  }
  .item button {
    margin-top: 10px;
    width: 100%;
  }
  .button-wrapper{
    width: calc(100% - 40px);
  }
}