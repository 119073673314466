.block {
  padding-top: 100px;
  width: 100%;
  background: rgba(245, 245, 247, 1);
}
.content-block {
  max-width: 1300px;
  margin: 0 auto;
  height: max-content;
 

}
.content {
  width: 100%;
  margin-top: 70px;
  margin-bottom: 70px;
}
.title-block {
  display: flex;
  flex-direction: column;
}
.title {
  
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  font-family: var(--font-open);
  font-style: normal;
  color: rgba(40, 40, 40, 1);
}
.title-block span {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-open);
  font-style: normal;
  color: rgba(40, 40, 40, 1);
}
.price-blocks-wrapper{
  padding: 60px 0px 60px 0px;
}

@media (max-width:1340px){
  .content{
    width: calc(100% - 80px);
  }
}

@media (max-width: 1024px) {
  .content {
    align-items: center;
    justify-content: center;
    width:100%;
  }
  .title-block {
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .block {
    padding-top: 60px;
  }
  .title-block {
    width: calc(100% - 40px);
    margin: 0 auto;
    align-items: start;
  }
  .title{
    font-size: 24px;
  }
  .content {
    margin-top: 40px;
  }
}
