.block {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.content {
  width: calc(100vw - 320px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.content img {
  width: 570px;
  height: 570px;
}
.content__content {
  width: 398px;
  display: flex;
  flex-direction: column;
}
.content__content span:first-of-type {
  font-size: 40px;
  font-weight: 600;
  font-family: var(--font-open);
  font-style: normal;
  line-height: 54.47px;
  color: rgba(40, 40, 40, 1);
}
.content__content span:last-of-type {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 400;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 26px;
  color: rgba(40, 40, 40, 1);
}
.content__content button {
  margin-top: 20px;
  width: 100%;
  height: 55px;
  border: none;
  outline: none;
  background: rgba(52, 165, 115, 1);
  border-radius: 15px;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--font-open);
  font-style: normal;
  line-height: 25.2px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

@media (max-width: 820px) {
  .content {
    width: calc(100% - 240px);
    flex-direction: column;
  }
  .content img {
    width: 100%;
    height: max-content;
  }
  .content__content {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .content__content a {
    width: 398px;
  }
}

@media (max-width: 480px) {
  .content {
    width: calc(100% - 40px);
  }
  .content__content span:first-of-type {
    font-size: 24px;
    line-height: 32.68px;
  }
  .content__content span:last-of-type {
    font-size: 16px;
    line-height: 24.4px;
  }
  .content__content a {
    width: 100%;
  }
}
