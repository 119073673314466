.block {
  padding-top: 100px;
  max-width: 1300px;
  margin: 0 auto;
  height: max-content;
  position: relative;

}

.title {

  margin: 0 auto;
  font-size: 40px;
  font-weight: 600;
  font-family: var(--font-open);
  font-style: normal;
  line-height: 54.47px;
  color: rgba(40, 40, 40, 1);
  padding: 0px 0px 60px 0px;

}

.slider-wrapper {
  width: 100%;
  padding: 30px 0px 90px 0px;

}

.card-item {
  max-width: 570px;
  height: 360px;
  margin: 0 auto;
  border-radius: 15px;
  background: #F5F5F7;
}

.card-item-content {
  padding: 30px 40px 0px 40px;
}

.item-capture {
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px 0px 10px 0px;
}

.item-description {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.buttons-wrapper {
  position: absolute;
  right: 130px;
  bottom: 0px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons-wrapper img {
  opacity: 0.6;
  display: block;
  width: 100%;
}

.buttons-wrapper button {
  padding: 0px;
  margin: 0px;
  border: none;
  background-color: rgba(40, 40, 40, 0);
  cursor: pointer;
}

.buttons-wrapper button:hover {
  opacity: 1;
}

.slider-button-prev {
  margin-bottom: 6px;
  transform: rotate(180deg);
}

@media (max-width:1024px) {
  .block {
    max-width: calc(100% - 40px);
  }

  .buttons-wrapper {
    width: 100px;
    position: static;
    margin: 0 auto;
  }

  .slider-wrapper {
    padding: 30px 0px 30px 0px;
  }
}

@media (max-width:600px) {
  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0px;
  }

  .card-item {
    width: 320px;
    height: 400px;
  }

  .card-item-content {
    padding: 30px 20px 20px 20px;
  }

  .item-capture {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 0px 10px 0px;
  }

  .item-description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

}