.block {
  padding-top: 100px;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.content {
  width: 650px;
  height: max-content;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
  font-size: 33px;
  font-weight: 700;
  font-style: normal;
  line-height: 60px;
  color: rgba(52, 165, 115, 1);
}

.title span {
  color: rgba(40, 40, 40, 1);
}

.description {
  max-width: 650px;
  margin-top: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;



  color: rgba(40, 40, 40, 1);
}

.content button {
  margin-top: 30px;
  width: 320px;
  height: 55px;
  border: none;
  outline: none;
  border-radius: 15px;
  background: rgba(52, 165, 115, 1);
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 25.2px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.block img {
  width: 570px;
  height: 570px;
}

@media (max-width:1340px) {
  .block {
    width: calc(100% - 80px);
  }
}

@media (max-width:1180px) {
  .block img {
    width: 430px;
    height: max-content;
  }
}

@media (max-width: 1024px) {
  .block {
    width: calc(100% - 80px);
    flex-direction: column-reverse;
    justify-content: center;
  }

  .content {
    align-items: center;
    justify-content: center;
  }

  .description {
    max-width: 100%;
    text-align: center;
  }

  .content button {
    width: 420px;
  }

  .block img {
    width: 100%;
    height: max-content;
  }

  .title {
    text-align: center;
  }

  .description {
    max-width: calc(100% - 80px);
    text-align: center;
  }
}

@media (max-width: 480px) {
  .block {
    width: calc(100% - 40px);
    flex-direction: column;
  }

  .content {
    width: 100%;
  }

  .title {
    width: 100%;
    font-size: 32px;
    line-height: 38.4px;
  }

  .description {
    max-width: 100%;
    margin-top: 15px;
    font-size: 18px;
    line-height: 23.4px;
    text-align: center;
  }

  .content button {
    margin-top: 15px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 19px;
  }

  .description {
    font-size: 16px;
  }
}