.blog-wrapper{
    max-width: 1300px;
    margin: 0 auto;
    .blog-top{

        margin-top: -45px;
        .blog-top-content{
            max-width: 1300px;
            padding-top: 250px;
            margin: 0 auto;
            text-align: start;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            .blog-main-image-block img{
                display: block;
                width: 100%;
                margin: 0 auto;
            }
            .blog-text-block{
                max-width: 570px;
                .blog-capture{   
                    font-size: 46px;
                    font-style: normal;
                    font-weight: 600;
                    padding-bottom: 10px;
                }
                @media (max-width:428px) {
                    .blog-capture{
                       font-size: 36px;
                    }
                }
                .blog-description{
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                    padding-bottom: 120px;
                    display: flex;
                    flex-wrap: wrap;
                }
                @media (max-width:428px) {
                    .blog-description{
                       font-size: 18px;
                       padding-bottom: 60px;
                       text-align: justify;
                    }
                }
                .blog-green{
                    color:#34A573 ;
                }
            }
        }
        @media (max-width:1071px) {
            .blog-top-content{
                .blog-text-block{
                    margin: 0 auto;
                }
                .blog-main-image-block{
                    margin: 0 auto;
                }
            }
        }
    }
    .blog-content{
        position: relative;
        max-width: 380px;
        height: 580px;
        border-radius: 16px;
        box-sizing: border-box;
        overflow: hidden;
        margin: 15px auto;
        background: #F5F5F7;
        .blog-main-item-wrapper{
            padding:  10px;
            .blog-text-content{
                padding: 0px 16px 0px 16px;
                .blog-date{
                    font-size: 16px;
                    opacity: 0.6;
                    padding: 10px 0px 0px 0px;
                }
                .blog-text-title{
                    font-size: 20px;
                    padding: 10px 0px 20px 0px;
                }
                .blog-text-description{
                    font-size: 16px;
                    opacity: 0.7;
                }
            }
        }
        .blog-text-content{
            padding: 0px 16px 0px 16px;
            .blog-date{
                font-size: 16px;
                opacity: 0.6;
                padding: 10px 0px 0px 0px;
            }
            .blog-text-title{
                font-size: 20px;
                padding: 10px 0px 20px 0px;
                color:#282828
            }
            .blog-text-description{
                font-size: 16px;
                opacity: 0.7;
            }
        }
        .blog-button{
            display: flex;
            position: absolute;
            bottom:16px;
            left: 16px;
            cursor: pointer;
        } 
        .blog-button button{
            background-color: rgba(0, 0, 0, 0);
            border: none;
            color: #34A573;
            padding: 0;
            padding-bottom: 4px;
            font-size: 20px;
            cursor: pointer;
        }
    }
    .blog-slider-block{
        padding: 70px 0px 80px 0px;
        background-color: #fff;
        border-radius: 48px 48px 0px 0px;
        margin-top: -34px;
    }
}
@media (max-width:1329px) {
    .blog-wrapper{
        max-width: calc(100% - 80px);
        margin: 0 auto;
    }
}
@media (max-width:428px) {
    .blog-wrapper{
        max-width: calc(100% - 40px);
        margin: 0 auto;
    }
}