.block {
  padding-top: 100px;
  max-width: 1300px;
  margin: 0 auto;
  height: max-content;

}

.title {
  width: 100%;
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  font-family: var(--font-open);
  font-style: normal;
  line-height: 54.47px;
  color: rgba(40, 40, 40, 1);
  padding: 0px 0px 60px 0px;
}

.title span {
  color: rgba(52, 165, 115, 1);
}

.content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0px 0px 60px 0px;
}

.benefits {
  width: 570px;

}

.item {
  width: 70%;
  display: flex;
  align-items: center;
  padding: 0px 0px 20px 0px;
  padding: 10px 20px 10px 20px;
}
.item img{
  display: block;
  padding: 0px 10px 0px 0px;
}

@media (max-width:1024px) {
  .title{
    padding: 0px 0px 60px 20px;
  }
}

@media (max-width:600px) {
  .title{
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0px;
    padding: 10px;
  }
}