.pop-up {
  width: calc(100%);
  margin: 0 auto;
  height: max-content;
  background: rgba(40, 40, 40, 1);
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.pop-up__content {
  width: calc(100% - 80px);
  height: max-content;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.content-block {
  display: flex;
  flex-direction: column;
  max-width: 835px;
}
.content-block span,
a{
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 20.8px;
  color: rgba(255, 255, 255, 1);
}
.content-block a {
  margin-top: 10px;
  font-weight: 700;
}
.pop-up__content button {
  margin-left: 25px;
  cursor: pointer;
  width: 260px;
  height: 49px;
  border-radius: 15px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
  border-width: 1px;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 19.2px;
  color: rgba(255, 255, 255, 1);
}

@media (max-width: 860px) {
  .pop-up {
    width: calc(100%);
  }
  .pop-up__content {
    width: calc(100% - 40px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .pop-up__content a {
    margin-top: 20px;
    font-size: 16px;
    line-height: 23.6px;
  }
  .pop-up__content button {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 680px) {
  .pop-up {
    width: calc(100%);
    left: 0px;
  }
  .pop-up__content {
    width: calc(100% - 40px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pop-up__content span:first-of-type {
    text-align: center;
    font-size: 14px;
    line-height: 19.6px;
  }
  .content-block {
    align-items: center;
    justify-content: center;
  }
  .pop-up__content a{
    margin-top: 10px;
  }
  .pop-up__content button {
    margin-left: 0;
    margin-top: 10px;
  }
}
