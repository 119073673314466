.block {
  padding-top: 100px;
  max-width: 1300px;
  margin: 0 auto;
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.block img {
  width: 570px;
  height: 570px;
}
.form-block {
  width: 570px;
  height: max-content;
  display: flex;
  flex-direction: column;
}
.title {
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  font-family: var(--font-open);
  font-style: normal;
  line-height: 54.47px;
  color: rgba(40, 40, 40, 1);
}
.input-field {
  margin-top: 40px;
  padding-left: 15px;
  width: calc(100% - 19px);
  height: 56px;
  border-radius: 15px;
  border-style: solid;
  border-color: rgba(207, 207, 207, 1);
  outline: none;

}

.input-field:not(:first-of-type) {
  margin-top: 20px;
}
.input-field::placeholder {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 19.2px;
  color: rgba(207, 207, 207, 1);
  text-transform: uppercase;
}
.check-box-block {
  margin-top: 20px;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.check-box-block__button {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  outline: none;
  padding: 0;
  width: 21px;
  height: 21px;
  background: transparent;
}
.check-box-block span {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 19.2px;
}
.form-block__button {
  margin-top: 30px;
  width: 100%;
  height: 55px;
  border: none;
  outline: none;
  border-radius: 15px;
  background: rgba(52, 165, 115, 1);
  font-size: 18px;
  font-weight: 600;
  font-family: var(--font-open);
  font-style: normal;
  line-height: 25.2px;
  color: rgba(255, 255, 255, 1);
}

@media (max-width:1180px){
  .block img {
    width: 430px;
    height: max-content;
  }
}

@media (max-width:1340px){
  .block{
    width: calc(100% - 80px);
  }
}

@media (max-width: 1024px) {
  .block {
    width: calc(100% - 80px);
    flex-direction: column-reverse;
  }
  .block img {
    width: 100%;
    height: max-content;
  }
  .form-block {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .check-box-block {
    width: calc(100% - 85px);
  }
  .title {
    text-align: center;
  }
  .input-field {
    width: calc(100% - 100px);
  }
  .form-block__button {
    width: calc(100% - 85px);
  }
}

@media (max-width: 480px) {
  .block {
    padding-top: 60px;
    width: calc(100% - 40px);
    flex-direction: column;
  }
  .form-block {
    align-items: unset;
  }
  .title {
    max-width: 90%;
    text-align: start;
    font-size: 24px;
    line-height: 32.68px;
  }
  .input-field {
    margin-top: 20px;
    padding-left: 10px;
    width: calc(100% - 16px);
  }
  .input-field:not(:first-of-type) {
    margin-top: 15px;
  }
  .check-box-block {
    width: 100%;
  }
  .form-block__button {
    margin-top: 20px;
    width: 100%;
  }
}
