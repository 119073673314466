.footer {
  margin-top: 100px;
  width: 100vw;
  position: relative;
  height: max-content;
  background: rgba(40, 40, 40, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.content-block {
  margin-top: 70px;
  width: calc(100% - 240px);
  height: max-content;
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
}
.content {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.link-block {
  display: flex;
  flex-direction: column;
}
.link-block a {
  text-decoration: none;
}
.link-block a:hover{
  color: #34A573;
}
.nav-item{
  padding-bottom: 10px;
}
.link-block__item {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 23.4px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.link-block__item:not(:first-of-type) {
  margin-top: 10px;
}
.address-block {
  display: flex;
  flex-direction: column;
}
.address-block span {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 23.4px;
  color: rgba(255, 255, 255, 1);
}
.address-block span:not(:first-of-type) {
  margin-top: 10px;
}
.contact-block {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.contact-block a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-family: var(--font-lato);
  font-style: normal;
  color: rgba(255, 255, 255, 1);
}
.contact-block a:not(:first-of-type) {
  margin-top: 10px;
}
.separator {
  margin-top: 40px;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 1);
}
.bottom-block {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bottom-block a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 23.4px;
  color: rgba(255, 255, 255, 1);
}
.bottom-block span {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--font-lato);
  font-style: normal;
  line-height: 23.4px;
  color: rgba(255, 255, 255, 1);
}

.social-links-wrapper{
 padding: 20px 0px 20px 0px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width:1340px){
  .content-block{
    width: calc(100% - 80px);
  }
}

@media (max-width: 1024px) {
  .content-block {
    width: calc(100% - 80px);
  }
}

@media (max-width: 480px){
  .footer{
    margin-top: 60px;
  }
  .content-block{
    margin-top: 40px;
    width: calc(100% - 40px);
    margin-bottom: 40px;
  }
  .content{
    flex-direction: column;
  }
  .logo{
    width: 190px;
    height: 29px;
  }
  .link-block{
    margin-top: 20px;
  }
  .address-block{
    margin-top: 20px;
  }
  .contact-block{
    margin-top: 20px;
  }
  .bottom-block{
    flex-direction: column;
    align-items: unset;
  }
  .bottom-block span:first-of-type{
    margin-top: 10px;
  }
}
