.block {
  padding-top: 100px;
  max-width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(245, 245, 245, 1);
}
.content-block {
  max-width: 1300px;
  margin: 0 auto;
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
}
.content {
  margin-top: 70px;
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 70px;
}
.content img {
  width: 570px;
  height: 570px;
}
.content__content {
  margin-left: 30px;
  width: 570px;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.title {
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  line-height: 54.47px;
  color: rgba(40, 40, 40, 1);
}
.title span {
  color: rgba(52, 165, 115, 1);
}
.description {
  margin-top: 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: rgba(40, 40, 40, 1);
  opacity: 0;
  transform: translateY(30px);
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
}
.show-element{
  opacity: 1;
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
}
.statistics-block {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 40px;
}
.statistics__item {
  width: 200px;
  height: max-content;
  display: flex;
  flex-direction: column;
}
.statistics__title {
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  line-height: 20.8px;
  color: rgba(40, 40, 40, 1);
}
.separator {
  margin-top: 6px;
  width: 100%;
  height: 1px;
  background: rgba(209, 209, 209, 1);
}
.count {
  margin-top: 10px;
  font-size: 60px;
  font-weight: 600;

  font-style: normal;
  line-height: 100%;
  color: rgba(52, 165, 115, 1);
  display: flex;
}
.plus{
  padding-left: 10px;
}
@media (max-width:1340px){
  .content{
    width: calc(100% - 80px);
  }
  .content-block{
    width: calc(100% - 40px);
  }
}

@media (max-width:1180px){
  .content img {
    width: 430px;
    height: max-content;
  }
}

@media (max-width: 1024px) {
  .content {
    width: calc(100% - 80px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .content__content {
    margin-left: 0;
    margin-top: 30px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .description {
    text-align: center;
  }
  .content img {
    width: 100%;
    height: max-content;
  }
}

@media (max-width: 480px) {
  .block {
    padding-top: 60px;
  }
  .content {
    margin-top: 40px;
    width: calc(100% - 40px);
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }
  .content__content{
    margin-top: 0;
    align-items: flex-start;
    margin-left: 0;
  }
  .title{
    font-size: 24px;
    line-height: 32.68px;
  }
  .description{
    text-align: start;
    font-size: 14px;
    line-height: 19.6px;
  }
  .statistics-block{
    margin-top: 20px;
    row-gap: 20px;
  }
  .statistics__item{
    width: 100%;
  }
  .count{
    font-size: 36px;
    line-height: 36px;
  }
  .content img{
    margin-top: 20px;
  }
}
