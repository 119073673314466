.dropdown{
    position: relative;
    color: #fff;
}
.dropdown-item{
    width: 60px;
    display: flex;
    padding-bottom: 10px;
    color: #fff;
    
}
.languageSelectorImage-main{
    display: block;
}


.lang-itam-label a {
    display: flex;
    background-color: aqua;
}
 
.dropdown-btn{
    display: flex;
}
.dropdown-btn:hover{
    color:rgba(52, 165, 115, 1);
    cursor: pointer;
}
.dropdown-btn img{
    padding-right: 4px;
}

.dropdown-content{
    position: absolute;
    top:50px;
    left: -7px;
    background: rgba(40, 40, 40, 1);
    width: 60px;
    color: #fff;
    border-radius: 8px;
}
.dropdown-content img{
    display: block;
    padding-right: 6px;
    padding-left: 6px;
}
.dropdown-content a{
    text-decoration: none;
   
}
.dropdown-content a:hover{
    color:rgba(52, 165, 115, 1);
   
}