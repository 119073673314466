.blogFull-content-wrapper {
    max-width: 1300px;
    margin: 0 auto;

    .blog-full-top {
        margin-top: 0px;

        .blog-full-top-content {
            width: 100%;
            padding: 200px 0px 100px 0px;
            margin: 0 auto;
            text-align: center;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .blogFull-text-block {
                max-width: 627px;
                text-align: start;

                .blogFull-capture {
                    font-size: 46px;
                    font-style: normal;
                    font-weight: 600;
                    padding: 0px 0px 20px 0px;

                    .blogFull-green {
                        color: #34A573;
                    }
                }
                @media (max-width:428px) {
                    .blogFull-capture{
                       font-size: 36px;
                    }
                }
                .blogFull-description {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                }
            }
        }
        
        @media (max-width:1133px) {
            .blog-full-top-content {
                width: calc(100% - 80px);
            }
        }
        @media (max-width:1071px) {
            .blog-full-top-content {
                width: calc(100% - 40px);
                flex-direction: column;
                .blogFull-text-block{
                    padding: 0px 0px 40px 0px;
                    .blogFull-capture{
                        font-size: 36px;
                    }
                }
                .blogFull-main-image-block img{
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    

    .blog-full-body-content {
        margin-top: -34px;
        padding: 40px 0px 100px 0px;
        width: 100%;
        text-align: justify;
        background-color: #F5F5F7;
        .blog-full-nav {
            width: 90%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 100px 0px 40px 0px;

            .button-back {

                display: flex;
                justify-content: flex-start;

                font-size: 20px;

                .blog-arrow-position {
                    padding: 0px 6px 0px 0px;
                }
            }

            .date-of-creation {
                color: #88889C;
                font-size: 18px;

            }
        }

        .blog-full-nav a {
            text-decoration: none;
            color: #34A573;
        }

        .blog-full-body-content-position {
            width: 90%;
            margin: 0 auto;
            font-size: 20px;

            .custom-image-style {
                display: block;
                margin: 0 auto;
            }
        }

        .body-full-author {
            padding: 0px 0px 60px 0px;
        }
    }
    .blog-full-body-content a{
        color: #34A573;
        text-decoration: none;
        font-size: 20px;
    }
    .blog-full-body-content a:hover {
        target-new: tab;
    }
    @media (max-width:428px) {

        .blog-full-body-content{
            
            .blog-full-nav{
                .date-of-creation{
                    font-size: 16px;
                }
                .button-back{
                    font-size: 16px;
                    .blog-arrow-position{
                        padding-top: 5px;
                    }
                    .blog_full_back_button{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }           
            }
        }
    
    }
}